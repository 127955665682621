// Pages
import Contact from '../pages/contact';
import LandingPage from '../pages/landing';
import SignUpPage from '../pages/sign-up';
import ViewAccountsPage from '../pages/view-accounts';
import SignInPage from '../pages/sign-in';
import PasswordForgetPage from '../pages/password-forget';
import Dashboard from '../pages/dashboard';
import All360Projects from '../pages/360-projects';
import GoalTrackerProjects from '../pages/goal-tracker-projects';
import TeamAssessmentProjects from '../pages/team-assessment-projects';
import AdminPage from '../pages/admin';
import SelectAccount from '../pages/select-account';
import AccountPage from '../pages/account';
import AccessPage from '../pages/access';
//
import Edit360 from '../pages/360/edit';
import UserComparisonReport from '../pages/360/ComparisonReport';
import User360Report from '../pages/360/userreport';
import UserGoalReport from '../pages/360/usergoalreport';
import Edit360Basic from '../pages/360/edit/basic';
import Edit360Brand from '../pages/360/edit/standard/brand';
import Edit360Standard from '../pages/360/edit/standard';
import Edit360Relationships from '../pages/360/edit/standard/relationships';
import Edit360Email from '../pages/360/edit/standard/email';
import Edit360ReviewerEmail from '../pages/360/edit/standard/reviewerReminder';
import Edit360Group from '../pages/360/group/edit';
import Edit360GroupUser from '../pages/360/user/edit';
import Edit360Questionnaire from '../pages/360/edit/questionnaire';
import Edit360QuestionnaireSection from '../pages/360/edit/questionnaire-section';
import QuestionnairePreview from '../pages/360/edit/questionnaire-preview';
import Edit360Pro from '../pages/360/edit/pro';
import GroupEvents from '../pages/groupEvents';
import Group360Report from '../pages/360/group360Report';
import SelfReport from '../pages/360/self-report';
import teamReport from '../pages/360/teamReport';
import EditMultichoiceLabels from '../pages/360/edit/standard/labels'

const pageRoutes = [
  {
    path: '/admin/signup/',
    component: SignUpPage,
    exact: true,
  },
  {
    path: '/access/:accountId/:userEmail/',
    component: AccessPage,
    exact: true,
  },
  {
    path: '/admin/accounts/',
    component: ViewAccountsPage,
    exact: true,
  },
  {
    path: '/contact/:accountId/',
    component: Contact,
    exact: true,
  },
  {
    path: '/dashboard/',
    component: SelectAccount,
    exact: true,
  },
  {
    path: '/',
    component: SignInPage,
    exact: true,
  },
  {
    path: '/admin/',
    component: AdminPage,
    exact: true,
  },
  {
    path: '/password-reset/',
    component: PasswordForgetPage,
    exact: true,
  },
  {
    path: '/dashboard/:accountId/360-projects/',
    component: All360Projects,
    exact: true,
  },
  {
    path: '/dashboard/:accountId/goal-tracker-projects/',
    component: GoalTrackerProjects,
    exact: true,
  },
  {
    path: '/dashboard/:accountId/team-assessment-projects/',
    component: TeamAssessmentProjects,
    exact: true,
  },
  {
    path: '/dashboard/:accountId/',
    component: Dashboard,
    exact: true,
  },
  {
    path: '/dashboard/:accountId/account/',
    component: AccountPage,
    exact: true,
  },
  {
    path: '/dashboard/:accountId/account/defaultQuestionnaire/',
    component: Edit360Questionnaire,
    exact: true,
  },
  {
    path: '/dashboard/:accountId/account/defaultQuestionnaire/preview/',
    component: QuestionnairePreview,
    exact: false,
  },
  {
    path: '/dashboard/:accountId/account/defaultQuestionnaire/:questionnaireSectionId/',
    component: Edit360QuestionnaireSection,
    exact: false,
  },
  {
    path: '/dashboard/:accountId/360s/:id/edit/',
    component: Edit360,
    exact: true,
  },
  {
    path: '/dashboard/:accountId/360s/:id/edit/questionnaire/',
    component: Edit360Questionnaire,
    exact: true,
  },
  {
    path: '/dashboard/:accountId/360s/:id/edit/questionnaire/preview/',
    component: QuestionnairePreview,
    exact: false,
  },
  {
    path: '/dashboard/:accountId/360s/:id/edit/questionnaire/:questionnaireSectionId/',
    component: Edit360QuestionnaireSection,
    exact: false,
  },
  {
    path: '/dashboard/:accountId/360s/:id/edit/standard/brand/',
    component: Edit360Brand,
    exact: false,
  },
  {
    path: '/dashboard/:accountId/360s/:id/edit/basic/',
    component: Edit360Basic,
    exact: false,
  },
  {
    path: '/dashboard/:accountId/360s/:id/edit/standard/relationships/',
    component: Edit360Relationships,
    exact: false,
  },
  {
    path: '/dashboard/:accountId/360s/:id/edit/standard/email/',
    component: Edit360Email,
    exact: false,
  },
  {
    path: '/dashboard/:accountId/360s/:id/edit/standard/revieweremail/',
    component: Edit360ReviewerEmail,
    exact: false,
  },
  {
    path: '/dashboard/:accountId/360s/:id/edit/standard/labels/',
    component: EditMultichoiceLabels,
    exact: false,
  },
  {
    path: '/dashboard/:accountId/360s/:id/edit/standard/',
    component: Edit360Standard,
    exact: false,
  },

  {
    path: '/dashboard/:accountId/360s/:id/comparison/:groupId/:userId/:previousGroupId/',
    component: UserComparisonReport,
    exact: false,
  },
  {
    path: '/dashboard/:accountId/360s/:id/report/:groupId/:userId/self-report/',
    component: SelfReport,
    exact: false,
  },
  {
    path: '/dashboard/:accountId/360s/:id/report/:groupId/:userId/',
    component: User360Report,
    exact: false,
  },
  {
    path: '/dashboard/:accountId/360s/:id/goal-report/:groupId/:userId/',
    component: UserGoalReport,
    exact: false,
  },
  {
    path: '/dashboard/:accountId/360s/:id/edit/group/:groupId/user/:userId/',
    component: Edit360GroupUser,
    exact: false,
  },
  {
    path: '/dashboard/:accountId/360s/:id/edit/group/:groupId/',
    component: Edit360Group,
    exact: true,
  },
  {
    path: '/dashboard/:accountId/360s/:id/edit/group/:groupId/report/',
    component: Group360Report,
    exact: true,
  },
  {
    path: '/dashboard/:accountId/360s/:id/edit/group/:groupId/:userId/team-report/',
    component: teamReport,
    exact: true,
  },
  {
    path: '/dashboard/:accountId/360s/:id/edit/group/:groupId/events',
    component: GroupEvents,
    exact: true,
  },
];

export default pageRoutes;
