import React, { Component } from 'react';
import { db } from '../firebase';
import { IconEditCircle, IconCrossCircle, IconTickCircle, UpArrow, DownArrow } from './../svg/svgs';

class StaticQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      questionTitle: this.props.questionTitle,
      answerType: this.props.answerType,
      questionRequired: this.props.questionRequired,

      questionID: this.props.index,
      questionnaireID: this.props.questionnaireID,
      sectionID: this.props.sectionID,

      working: false,

      formClass: 'flex br3 mv3 pv3 pv1 bg--pale',
      selectClass: 'bg-transparent bn gray',
      inputClass: 'reset-mar transparent no-border',
      inputDateClass: 'mv2 pv2 bg-transparent input-reset bn gray outline-0',
    };

    // this.updateQuestion = this.updateQuestion.bind(this);
    // this.deleteQuestion = this.deleteQuestion.bind(this);
    // this.editQuestion = this.editQuestion.bind(this);
  }
  updateQuestion(e) {
    let questionData = {
      answerType: this.answerType.value,
      questionTitle: this.questionTitle.value,
      required: this.questionRequired.value,
    };

    this.setState({
      working: true,
    });

    db.updateQuestion(this.props.accountId, this.state.questionnaireID, this.state.sectionID, this.state.questionID, questionData).then((data) => {
      this.setState({
        editing: false,
        working: false,
        formClass: 'flex br3 mv3 pv3 pv1 bg--pale',
        selectClass: 'bg-transparent bn gray',
        inputClass: 'reset-mar transparent no-border',
        inputDateClass: 'mv2 pv2 bg-transparent input-reset bn gray outline-0',
      });
    });

    e.preventDefault();
  }
  reOrder(e, questionID, sort) {
    this.props.orderAfterDeletion(e, questionID, sort);
  }
  deleteQuestion(e) {
    db.doDeleteQuestion(this.props.accountId, this.state.questionnaireID, this.state.sectionID, this.state.questionID);
    this.reOrder(e, this.state.questionID, this.props.sort);

    e.preventDefault();
  }
  editQuestion(e) {
    this.setState({
      editing: true,
      formClass: 'flex br3 mb3 mt2 pv3 bg--footerColor',
      selectClass: 'bg-transparent bn white',
      inputClass: 'reset-mar transparent no-border color--white',
      inputDateClass: 'mv2 pv2 bg-transparent input-reset bn white outline-0',
    });
    this.questionTitle.focus();
    e.preventDefault();
  }

  moveUp(e, orderBy) {
    e.preventDefault();
    this.props.questionUp(e, orderBy);
  }

  moveDown(e, orderBy) {
    e.preventDefault();
    this.props.questionDown(e, orderBy);
  }

  render() {
    const upToggle = () => {
      if (this.props.legacyMode === true) {
        return '';
      } else {
        if (this.props.upBtnDisabled === true) {
          return '';
        } else {
          return (
            <button className='has-tooltip' data-tooltip='Move Question Up' onClick={(e) => this.moveUp(e, this.props.sort)}>
              <UpArrow width='24' height='24' fill='#3b4e7e' title='Move Question Up' />
            </button>
          );
        }
      }
    };
    const upArrow = upToggle();

    const downToggle = () => {
      if (this.props.legacyMode === true) {
        return '';
      } else {
        if (this.props.downBtnDisabled === true) {
          return <div className='w-100 ml pl3' />;
        } else {
          return (
            <button
              disabled={this.props.downBtnDisabled}
              className='has-tooltip'
              data-tooltip='Move Question Down'
              onClick={(e) => this.moveDown(e, this.props.sort)}
            >
              <DownArrow width='24' height='24' fill='#3b4e7e' title='Move Question Down' />
            </button>
          );
        }
      }
    };
    const downArrow = downToggle();

    return (
      <form action='/' className={this.state.formClass} onSubmit={this.updateQuestion}>
        <div className='w-60 pl3'>
          <input
            disabled={!this.state.editing}
            ref={(input) => (this.questionTitle = input)}
            className={this.state.inputClass}
            type='text'
            defaultValue={this.state.questionTitle}
          />
        </div>

        <div className='w-40 flex justify-around items-center'>
          <div className='bl w-100 tc b--gray'>
            <select
              disabled={!this.state.editing}
              ref={(input) => (this.answerType = input)}
              className={this.state.selectClass}
              defaultValue={this.state.answerType}
            >
              {this.props.current360Type !== 'Goal Tracker' ? <option value='MultiChoice'>Rating</option> : ''}
              <option value='FreeText'>Text</option>
              {this.props.current360Type == 'Goal Tracker' ? <option value='Date'>Date</option> : ''}
            </select>
          </div>

          <div className='bl w-100 tc b--gray'>
            <select
              disabled={!this.state.editing}
              ref={(input) => (this.questionRequired = input)}
              className={this.state.selectClass}
              defaultValue={this.state.questionRequired}
            >
              <option value='true'>Mandatory</option>
              <option value='false'>Optional</option>
            </select>
          </div>

          <div className='bl w-100 tc b--gray flex'>
            {this.state.editing ? (
              <button className='w-100' disabled={this.state.working ? 'disabled' : ''} onClick={(e) => this.updateQuestion(e)}>
                <IconTickCircle width='25' height='25' fill='#00dca8' />
              </button>
            ) : (
              <div className={this.props.legacyMode ? 'tc w-100' : 'flex w-100'}>
                <button className='mh1 has-tooltip' data-tooltip='Edit' type='submit' onClick={(e) => this.editQuestion(e)}>
                  <IconEditCircle width='30' height='30' fill='#0051ff' />
                </button>
                <button className='mh1 has-tooltip' data-tooltip='Delete' onClick={(e) => this.deleteQuestion(e)}>
                  <IconCrossCircle width='30' height='30' fill='#3b4e7e' />
                </button>

                {upArrow}
                {downArrow}
              </div>
            )}
          </div>
        </div>
      </form>
    );
  }
}

export default StaticQuestion;
