import React, { Component } from 'react';
import PageHeader from '../PageHeader';
import LoadingState from '../global/loading-state';

export default class QuestionFreeTextFeedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      reRenderStage: 0,
    };
  }

  componentDidMount() {
    const {commentsByRelationship, relationshipKeys, reportType} = this.props
    let chartPages = []
    if (reportType !== 'Team') {
      chartPages.push({relationshipID: 'Self', lowerLimit: 0, upperLimit: 1})
    }
    if (relationshipKeys) {
      relationshipKeys.forEach(relationshipID => {
        let numberOfComments = 0;
        if (commentsByRelationship && commentsByRelationship[relationshipID]) {
          numberOfComments = commentsByRelationship[relationshipID].length;
        }
        const pageRelationship = {relationshipID, lowerLimit: 0, upperLimit: numberOfComments}
        chartPages.push(pageRelationship)
      })
    }
    this.setState({ loading: false, chartPages: [chartPages] });
  }

  componentDidUpdate(prevProps, prevState) {
    const {relationshipKeys, questionID, commentsByRelationship, updateQuestionsRerenderedForPDF, reportType} = this.props

    // initial re-render will change the page width to match space available in PDF
    if (prevProps.pdfRequested !== this.props.pdfRequested) {
      if (!this.state.loading) {
        this.setState({ reRenderStage: 1 });
      }
    }

    // second re-render, calculates page breaks first, while in PDF matched width, then updates with correct breaks but back to normal width
    if (prevState.reRenderStage !== this.state.reRenderStage) {
      if (this.state.reRenderStage === 1) {
        // will be populated by currentPage sub-arrays
        let updatedChartPages = [];
        // contains objects with each relationship to appear on respective page and comment index limits
        let currentPage = [];
        // sets initial count for available space on a page
        let availableSpace = 705;
        let maxAvailableSpace = 705;
        // this and some other rowHeight declarations add 1, to account for rounding down in offsetHeight property
        const questionTitleHeight = this.titleRow.offsetHeight + 1;
        // update available space
        availableSpace -= questionTitleHeight;
        maxAvailableSpace -= questionTitleHeight;
        if (reportType !== 'Team') {
          const SelfAnswersRowHeight = this.SelfAnswersRowHeight ? this.SelfAnswersRowHeight.offsetHeight + 1 : 0;
          // sets comment limits for Self responses and adds this data to the current page. Then reduces available page space accordingly
          const pageSelfData = {relationshipID: 'Self', lowerLimit: 0, upperLimit: 1};
          currentPage.push(pageSelfData);
          // update available space
          availableSpace -= SelfAnswersRowHeight;
          maxAvailableSpace -= SelfAnswersRowHeight;
        }
  
        if (relationshipKeys) {
          const numberOfRelationships = relationshipKeys.length;
          // adds a relationship to currentPage and updates available space
          const addRelationshipRowToPage = (relationshipData, relationshipRowHeight) => {
            currentPage.push(relationshipData);
            availableSpace -= relationshipRowHeight;
          }
  
          // adds to list of pages, then resets relevant variables
          const pushPageAndReset = (relationshipID, lowerLimit, upperLimit, rowHeight) => {
            updatedChartPages.push(currentPage);
            currentPage = [{relationshipID, lowerLimit, upperLimit}];
            maxAvailableSpace = 705;
            availableSpace = maxAvailableSpace - rowHeight;
          }
          // runs through each relationship and assigns which pages respective comments will appear on to prevent overflow
          relationshipKeys.forEach((relationshipID, relationshipindex) => {
            let relationshipRowHeight = 0;
            if (this[`relationshipRow${relationshipID}`]) {
                relationshipRowHeight = this[`relationshipRow${relationshipID}`].offsetHeight + 1;
            }
            // label height may be needed for calculations when a relationships responses are too big to fit on one page
            let relationshipNameLabelHeight = 0;
            if (this[`${relationshipID}relationshipLabel`]) {
                relationshipNameLabelHeight = this[`${relationshipID}relationshipLabel`].offsetHeight + 30;
            }
            let comments;
            if (commentsByRelationship && commentsByRelationship[relationshipID]) {
              comments = commentsByRelationship[relationshipID];
            }
            let numberOfComments = 0;
            if (comments) {
                numberOfComments = comments.length;
            }
            // sets initial comment limits for the relationship, which will be added to currentPage data when necessary
            let lowerLimit = 0;
            let upperLimit = numberOfComments;
    
            // adds 50 to calculations to ensure a little whitespace at bottom of page
            if (availableSpace >= (relationshipRowHeight + 50)) {
              // All comments will fit on current page
              const relationshipPageData = {relationshipID, lowerLimit, upperLimit};
              addRelationshipRowToPage(relationshipPageData, relationshipRowHeight);
            } else {
              // if greater than available space, will need to move relationship to a new page or split it between pages
              if (maxAvailableSpace >= (relationshipRowHeight + 50) && (relationshipindex > 0 && availableSpace < (maxAvailableSpace / 2))) {
                // all comments will fit on a fresh page, moves them to the next
                pushPageAndReset(relationshipID, lowerLimit, upperLimit, relationshipRowHeight);
              } else {
                // all comments take up too much space, relationship will need to be split by comments to prevent overflow
    
                // calculations must account for the relationship name label each time it appears on a page
                availableSpace -= relationshipNameLabelHeight;
    
                // loop through comments and establish where to split and move to next page
                comments.forEach((comment, commentIndex) => {
                  let movePage = false
                  // +10 accounts for offsetHeight rounding and margins
                  const commentRowHeight = this[`${relationshipID}commentRow${commentIndex}`].offsetHeight + 10;
                  // prevents first comment in relationship from rendering alone
                  if (commentIndex === 0 && numberOfComments > 1) {
                    const combinedCommentHeights = commentRowHeight + this[`${relationshipID}commentRow${1}`].offsetHeight + 10;
                    if (availableSpace < (combinedCommentHeights + 50)) {
                      movePage = true;
                    }
                  } else {
                    if (availableSpace < (commentRowHeight + 50)) {
                      movePage = true;
                    }
                  }
                  
                  if (!movePage) {
                    // Space free on current page for some comments
                    availableSpace -= commentRowHeight;
                  } else {
                    // comments will move to next page
                    if (commentIndex === 0) {
                      // starts fresh on a new page
                      updatedChartPages.push(currentPage);
                      maxAvailableSpace = 705;
                      availableSpace = maxAvailableSpace - (relationshipNameLabelHeight + commentRowHeight);
                      currentPage = [];
                    } else {
                      // sets limit for current page and pushes this to array of pages, then updates and resets variables
                      upperLimit = commentIndex;
                      currentPage.push({relationshipID, lowerLimit, upperLimit});
                      updatedChartPages.push(currentPage);
                      currentPage = [];
                      lowerLimit = commentIndex;
                      maxAvailableSpace = 705;
                      availableSpace = maxAvailableSpace - (relationshipNameLabelHeight + commentRowHeight);
                    }
                  }
                  if (commentIndex === numberOfComments - 1) {
                    // makes final update to relationship split when last comment in list
                    upperLimit = numberOfComments;
                    currentPage.push({relationshipID, lowerLimit, upperLimit});
                    availableSpace -= commentRowHeight;
                  }
                })
              }
            }
            if (relationshipindex === numberOfRelationships - 1) {
              // if on last relationship, push current page to pages array to complete the process
                updatedChartPages.push(currentPage);
            }
          })
        } else {
          updatedChartPages.push(currentPage)
        }
        this.setState({ chartPages: updatedChartPages, reRenderStage: 2 });
      } else if (this.state.reRenderStage === 2) {
        // re-renders complete, can now complete PDF export process
        updateQuestionsRerenderedForPDF(questionID);
      }
    }
  }

  render() {
    const {loading, reRenderStage, chartPages} = this.state;
    const {questionID, questionIndex, stepNumber, questionTitle, current360Relationships, userFreetextResponses, commentsByRelationship, responsesPresent} = this.props;

    return !loading ? (
      chartPages.map((page, pageIndex) => {
        return (
          <div
            className='mb4 page-break user-report__content user-report__content--no-pad' 
            key={questionID + pageIndex} 
            style={{ width: reRenderStage === 1 ? '158mm' : '', marginTop: '34.015px'}}
          >
            <div className='average-rating-section-reflection'>
              <PageHeader h2Class='mt4' h2Text='Part 2: ' strong={`Step ${stepNumber}`} h3Text={`Text Feedback ${questionIndex > 0 || pageIndex > 0 ? '(continued)' : ''}`} />
            </div>
              {/* Question Title */}
              {pageIndex === 0 && (
                <div className='average-rating-section-reflection' ref={(titleRow) => {this.titleRow = titleRow}}>
                  <h4 className='pt4 f5'>
                    <strong>{questionTitle}</strong>
                  </h4>
                </div>
              )}

              {!responsesPresent && (
                <div 
                  key={questionID + pageIndex} 
                  className='average-rating-section-reflection' 
                >
                  <p>No responses were provided for this question.</p>
                </div>
              )}

              {page.map((relationship, relationshipIndex) => {
                const {relationshipID, lowerLimit, upperLimit} = relationship;
                // user response
                if (relationshipID === 'Self') {
                  return userFreetextResponses && (
                    <div key={questionID + pageIndex + relationshipID} className='average-rating-section-reflection' ref={(SelfAnswersRowHeight) => {this.SelfAnswersRowHeight = SelfAnswersRowHeight}}>
                      <div className='mt4'>
                        <strong>Self:</strong>
                      </div>
                      <div>
                        <p>{userFreetextResponses.comment}</p>
                      </div>
                    </div>
                  )
                } else {
                  // reviewer responses
                  const relationshipName = current360Relationships[relationshipID].name;
                  let comments;
                    if (commentsByRelationship) {
                      if (commentsByRelationship[relationshipID]) {
                        comments = commentsByRelationship[relationshipID];
                      }
                  }
                  return comments && (
                    <div 
                      key={questionID + pageIndex + relationshipID} 
                      className='average-rating-section-reflection' 
                      ref={(relationshipRow) => {this[`relationshipRow${relationshipID}`] = relationshipRow}}
                    >
                      <div className='mt4' ref={(relationshipLabel) => {this[`${relationshipID}relationshipLabel`] = relationshipLabel}}>
                        <strong>{relationshipName}{lowerLimit > 0 ? ' (continued)' : ''}:</strong>
                      </div>
                      {comments.map((comment, commentIndex) => {
                        return commentIndex >= lowerLimit && commentIndex < upperLimit && (
                          <div
                           key={questionID + pageIndex + relationshipID + commentIndex} 
                           ref={(commentRow) => {this[`${relationshipID}commentRow${commentIndex}`] = commentRow}}
                          >
                            <p>{comment}</p>
                          </div>
                        )
                      })}
                    </div>
                  )
                }
              })}
          </div>
        )
      })
    ) : (
      <LoadingState />
    )
  }
}

