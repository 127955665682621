import React, { Component } from 'react';
import QuestionFreeTextFeedback from './QuestionFreeTextFeedback';
import LoadingState from '../global/loading-state';

export default class FreeTextFeedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    const { userFreetextResponses, reviewerFreetextResponses } = this.props;
    // check to see if any text responses have beenn provided
    let responseIDs = [];
    if (userFreetextResponses) {
      for (const questionID in userFreetextResponses) {
        responseIDs.push(questionID);
      }
    }
    if (reviewerFreetextResponses) {
      for (const questionID in reviewerFreetextResponses) {
        if (!responseIDs.includes(questionID)) {
          responseIDs.push(questionID);
        }
      }
    }
      
    this.setState({ loading: false, responsesPresent: responseIDs.length > 0 });
  }

  render() {
    const {responsesPresent, loading} = this.state;
    const {reportType, stepNumber, freeTextQuestionIDs, questionDetails, freeTextQuestionDetails, relationshipKeys, current360Relationships, userFreetextResponses, reviewerFreetextResponses, pdfRequested, updateQuestionsRerenderedForPDF} = this.props;

    return !loading ? (
      freeTextQuestionIDs && freeTextQuestionIDs.map((questionID, questionIndex) => {
        const {questionTitle} = freeTextQuestionDetails[questionID]
        let commentsByRelationship
        if (reviewerFreetextResponses) {
          if (reportType === 'Team') {
            commentsByRelationship = reviewerFreetextResponses[questionID]
          } else {
            commentsByRelationship = reviewerFreetextResponses[questionID].commentsByRelationship
          }
          
        }

        return (
          <QuestionFreeTextFeedback 
            key={questionID}
            questionID={questionID}
            questionIndex={questionIndex}
            reportType={reportType}
            stepNumber={stepNumber}
            questionTitle={questionTitle}
            relationshipKeys={relationshipKeys}
            current360Relationships={current360Relationships}
            userFreetextResponses={userFreetextResponses ? userFreetextResponses[questionID] : undefined}
            commentsByRelationship={commentsByRelationship}
            pdfRequested={pdfRequested}
            updateQuestionsRerenderedForPDF={updateQuestionsRerenderedForPDF}
            responsesPresent={responsesPresent}
          />
        )
      })
    ) : (
      <LoadingState />
    )
  }
}

